// /var/www/website2024/dev.afaa.website/s-293-spatime-dev/src/components/BookingPaymentFormStep.js

import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Spinner } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/scss/PaymentForm.scss';
import TimelineBookingStatus from '../components/TimelineBookingStatus';
import PaymentForm from '../components/PaymentForm';
import ListGroup from 'react-bootstrap/ListGroup';
// Import translations
import fixedRateData from '../data/FixedRateConnAPI.json';
import invoiceTranslations from '../data/InvoiceDataTranslations.json';

import { Modal } from 'react-bootstrap';
import RegisterForm from '../components/register.js'; // Adjust path as necessary


const BookingPaymentFormStep = ({ profileData }) => {
  const [bookingDetails, setBookingDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [savedBookingID, setSavedBookingID] = useState("");
  //const [totalPrice, setTotalPrice] = useState(0);
  const [showRegistrationModal, setShowRegistrationModal] = useState(false);
  const [autoGeneratedPassword, setAutoGeneratedPassword] = useState('');


  // Automatically check if the user is logged in when the component mounts
  useEffect(() => {
    const checkUserLoggedIn = () => {
      return !!localStorage.getItem('token'); // Simple token-based check
    };

    if (!checkUserLoggedIn()) {
      setShowRegistrationModal(true); // Show registration modal if not logged in
    }
  }, []);

  // In BookingPaymentFormStep.js, add a method to handle modal visibility
  const showRegistrationModalHandler = () => {
    setShowRegistrationModal(true);
  };


  // This useEffect should be at the top level inside the BookingPaymentFormStep function
  useEffect(() => {
    // Function to generate a random password
    const generateRandomPassword = () => {
      const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
      let retVal = "";
      for (let i = 0, n = charset.length; i < 8; ++i) {
        retVal += charset.charAt(Math.floor(Math.random() * n));
      }
      return retVal;
    };

    // Generate a new password and log it
    const newGeneratedPassword = generateRandomPassword();
    setAutoGeneratedPassword(newGeneratedPassword); // Set the password in the state
    console.log("Generated Password:", newGeneratedPassword); // Log the new password
  }, []); // The empty dependency array ensures this effect runs once on component mount  


  const [formData, setFormData] = useState({
    first_name: profileData ? profileData.first_name : '',
    sur_name: profileData ? profileData.sur_name : '',
    email: profileData ? profileData.email : '',
    telephone: profileData ? profileData.telephone : '',
  });

  const [submitted, setSubmitted] = useState(false);
  const submissionResponse = useSelector(state => state.booking.submissionResponse);

  // Function to calculate the total price including tax from booking details
  // Updated function to calculate subtotal, tax, and total

 
  const calculateTotalPrice = useCallback(() => {  
    // Calculate the subtotal by summing up all BookingDetailPrice
    const subTotal1 = bookingDetails.reduce((acc, detail) => acc + parseFloat(detail.BookingDetailPrice), 0);
 

    // Calculate the tax based on the subtotal and tax rate
    const taxRate = parseFloat(fixedRateData.tax) / 100;

   
    const taxValue = subTotal1 * taxRate;
    const subTotal = subTotal1 - taxValue;

    // Calculate the total price as the sum of subtotal and tax
    const total = subTotal + taxValue;

    // Print or console log the calculated values
    //console.log('Subtotal:', subTotal.toFixed(2));
    //console.log('Tax:', taxValue.toFixed(2));
    //console.log('Total:', total.toFixed(2));

    return { subTotal1,subTotal, taxValue, total };
  }, [bookingDetails]);


  // In your JSX, when displaying the calculated values:
  const { subTotal1, subTotal, taxValue, total } = calculateTotalPrice();

  // Persist BookingID to localStorage when submissionResponse changes
  useEffect(() => {
    if (submissionResponse && submissionResponse.Bookings && submissionResponse.Bookings.length > 0) {
      const bookingID = submissionResponse.Bookings[0].BookingID;
      localStorage.setItem('localBookingReduxID', bookingID);
      console.log('BookingID saved to localStorage:', bookingID);
    }
  }, [submissionResponse]);


  const onRegistrationSuccess = async () => {
    // Retrieve the BookingID and CustomerID from localStorage
    const bookingID = localStorage.getItem('localBookingReduxID');
    const customerID = localStorage.getItem('userData');
    const token = localStorage.getItem('token');
    const roundedAmountInBaseUnit = Math.ceil(parseFloat(subTotal));

    // Prepare the payload for the API call
    const updatePayload = [{
      "BookingID": bookingID,
      "CustomerID": customerID,
      "TotalAmount": roundedAmountInBaseUnit,
      "AmountPaid": roundedAmountInBaseUnit
    }];

    console.log('updatePayload :', updatePayload);

    try {
      // Call the API endpoint to update the booking with the customer's user ID
      const response = await fetch('https://py.testproject.work/booking/api/edit', {
        method: 'PUT', // Changing to PUT method as required
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
          // Include other headers as needed, such as Authorization header for the Bearer token
        },
        body: JSON.stringify(updatePayload)
      });

      // Check the response status and log the response data
      if (response.ok) {
        const data = await response.json();
        console.log('Booking updated successfully:', data);
        // You can close the modal and update UI as needed here
        setShowRegistrationModal(false);
      } else {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
    } catch (error) {
      console.error('Failed to update booking after registration:', error);
    }
  };


  const fetchServiceDetailsAndPrices = async (details) => {
    try {
      const pricesPromises = details.map((detail) =>
        fetch(`https://py.testproject.work/price/api/price_service?PriceID=${detail.ServiceID}&status=1`)
      );
      const pricesResponses = await Promise.all(pricesPromises);
      const pricesData = await Promise.all(pricesResponses.map(res => res.json()));

      // Print or console log the data sent to the API
      console.log('Data sent to the API:', details);

      // Print or console log the data received from the API
      console.log('Data received from the API:', pricesData);

      const enrichedDetails = details.map((detail, index) => {
        const { results } = pricesData[index];
        return { ...detail, ...results[0] };
      });

      setBookingDetails(enrichedDetails); // Update state with combined details
    } catch (error) {
      console.error('Failed to fetch service details and prices:', error);
    }
  };


  // Function to fetch booking details using BookingID
  const fetchBookingDetails = useCallback(async (bookingID) => {
    const apiUrl = `https://py.testproject.work/booking/api/detail_view?BookingID=${bookingID}`;
    try {
      const response = await fetch(apiUrl);
      const details = await response.json();
      await fetchServiceDetailsAndPrices(details);
    } catch (error) {
      console.error('Failed to fetch booking details:', error);
    }
  }, []);

  // Modify the useEffect hook that retrieves the BookingID from localStorage
  useEffect(() => {
    const savedBookingID = localStorage.getItem('localBookingReduxID');
    if (savedBookingID) {
      console.log('Retrieved BookingID from localStorage:', savedBookingID);
      setSavedBookingID(savedBookingID); // Store the retrieved ID in state
      fetchBookingDetails(savedBookingID);
    }
  }, [fetchBookingDetails]);


  const formatServiceTime = (serviceTime) => {
    // Assumes serviceTime is a string like '15:45:00'
    const [hours, minutes] = serviceTime.split(':');
    const date = new Date();
    date.setHours(+hours);
    date.setMinutes(+minutes);
    // Format to 12-hour time with AM/PM
    return date.toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit', hour12: true });
  };

  // Function to format service date to French format with the day and month
  const formatServiceDate = (serviceDate) => {
    // Assumes serviceDate is a string in ISO format like '2024-03-16'
    const date = new Date(serviceDate);
    // Format to French date with day and month
    return date.toLocaleDateString('fr-FR', { day: '2-digit', month: 'long' }).toUpperCase();
  };


  useEffect(() => {
    const fetchProfileData = async () => {
      const token = localStorage.getItem('token');
      const user_ID = localStorage.getItem('userData');

      if (!token || !user_ID) {
        console.error('No token or user ID found');
        // Optionally redirect to login
        return;
      }

      try {
        const response = await fetch(`https://py.testproject.work/register/api/user?user_ID=${user_ID}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        setFormData({
          first_name: data.first_name || '',
          sur_name: data.sur_name || '',
          email: data.email || '',
          telephone: data.telephone || '',
        });
      } catch (error) {
        console.error('Failed to fetch profile data:', error);
        // Optionally handle the error, e.g., by showing a notification
      }
    };

    fetchProfileData();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: value
    }));
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true);
    setIsLoading(true); // Start loading when the form is submitted

    const user_ID = localStorage.getItem('userData');

    if (!user_ID) {
      console.error('No user ID available for update');
      setIsLoading(false); // Stop loading due to error
      return;
    }

    console.log('Form Data:', formData); // Log the formData before sending it

    const updateUrl = `https://py.testproject.work/register/api/edit?user_ID=${user_ID}`;

    fetch(updateUrl, {
      method: 'PUT', // Change to PUT method for updating data
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}` // Assuming token is stored in localStorage
      },
      body: JSON.stringify(formData) // Send formData as JSON payload
    })
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })

      .then(data => {
        console.log('Success:', data);
        // Handle success, such as redirecting to a confirmation page or showing a success message
      })

      .catch((error) => {
        console.error('Error:', error);
        // Handle errors, such as displaying an error message to the user
      })
      .finally(() => {
        setIsLoading(false); // Stop loading when the request is complete
      });
  };




  // Helper function to get label text based on field name
  // Simplify the label retrieval by using a mapping or direct assignment if feasible
  const getLabelText = (fieldName) => {
    const labels = {
      first_name: 'Prénom',
      sur_name: 'Nom de famille',
      email: 'Email',
      telephone: 'Téléphone',
    };
    return labels[fieldName] || '';
  };


  return (
    <div className="schedule-step container box-main-index1">
      <TimelineBookingStatus bookedStatuses={['SERVICE', 'BOOK', 'PAYMENT']} />

      <Row>
        <Col md={8}>

          <Card>
            <Card.Header className="d-flex justify-content-between align-items-center">
              COORDONNÉES
              <Button
                className="custom-button"
                variant="primary"
                type="submit"
                form="coordonneesForm" // This should match the ID of the form
                disabled={isLoading}
              >
                {isLoading ? (
                  <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                ) : (
                  <i className="pi pi-save"></i> // Ensure your icon library is included
                )}
              </Button>
            </Card.Header>
            <Card.Body>
              <Form id="coordonneesForm" onSubmit={handleSubmit}> {/* Assign an ID to the form */}
                {Object.keys(formData).map((field) => (
                  <Form.Group key={field} controlId={`form${field}`}>
                    <Form.Label>{getLabelText(field)}</Form.Label>
                    <Form.Control
                      type={field === 'email' ? 'email' : 'text'}
                      name={field}
                      value={formData[field]}
                      onChange={handleChange}
                      isInvalid={submitted && !formData[field]}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      Ce champ est requis.
                    </Form.Control.Feedback>
                  </Form.Group>
                ))}
              </Form>
            </Card.Body>
          </Card>


          <Card className='mt-3 paymentForm-bg-1'>
            <Card.Header>POUR LE PAIEMENT</Card.Header>
            <Card.Body>

              <PaymentForm
                showRegistrationModal={showRegistrationModalHandler}
                savedBookingID={savedBookingID}
                customerEmail={formData.email}
                telephone={formData.telephone}
                totalAmount={total.toFixed(2)} // Make sure 'total' is up-to-date
                subTotalAmount={subTotal.toFixed(2)} // Pass the subtotal amount
                dateTime={new Date().toISOString()}
                firstName={formData.first_name} // Add this line
                surName={formData.sur_name}     // Add this line
              />

            </Card.Body>
          </Card>

        </Col>

        <Col md={4}>
          <Card className="booking-details-card">
            <Card.Header>DÉTAILS RÉSERVATION</Card.Header>
            <ListGroup className='mt-3 m-2' variant="flush">
              {bookingDetails.map((detail, index) => (
                <ListGroup.Item key={index} className="booking-list-item">
                  <div className="service-info">
                    {/* {detail.BookingDetailPrice} */}
                    <div className="service-name">{detail.ServiceName} {detail.PriceType} </div>
                    <span className="service-time">{formatServiceTime(detail.ServiceTime)}, {formatServiceDate(detail.ServiceDate)}</span>
                  </div>
                  <div className="service-price">{detail.BookingDetailPrice}€</div>
                </ListGroup.Item>
              ))}
            </ListGroup>

            <div className="total-price px-2">
              {invoiceTranslations.FR.SUB_TOTAL}: {subTotal.toFixed(2)}€
            </div>
            <div className="total-price px-2">
              {invoiceTranslations.FR.TAX} ({fixedRateData.tax}%): {taxValue.toFixed(2)}€
            </div>
            <div className="total-price px-2 pb-3">
              {invoiceTranslations.FR.TOTAL_TRA}: {subTotal1.toFixed(2)}€
            </div>
          </Card>
        </Col>

      </Row>


      <Modal show={showRegistrationModal} onHide={() => setShowRegistrationModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Inscrivez-vous pour continuer</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <RegisterForm
            onSuccess={onRegistrationSuccess}
            hidePasswordFields={true}
            autoGeneratedPassword={autoGeneratedPassword}
            initialFormData={formData}
          />

            <a className='text-forgot' href='/forgot'>Forgot Password</a>
        </Modal.Body>
      </Modal>

    </div>
  );
};


export default BookingPaymentFormStep;
