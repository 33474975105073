import React from 'react';
import '../styles/scss/footer.scss'; // นำเข้าไฟล์ SCSS

const Footer = () => {
    return (
        <footer className="py-5">
            {/* ส่วนของ Footer ที่ปรับปรุง */}
            <div className="container">
                <div className="row">
                    <div className="col-md-6">
                        <h5> Mirabeau thaï spa </h5>
                        <p>
                            10 RUE MIRABEAU<br />
                            75016 PARIS<br />
                            Tél: 01 42 30 78 28<br />
                            mirabeauthaispa@gmail.com
                        </p>
                    </div>
                    <div className="col-md-6 text-md-right">
                        <a href="/accueil">Accueil</a><br />
                        <a href="/services">Services</a><br />
                        <a href="/reservations">Réservations</a><br />
                        <a href="/contact">Contact</a><br />
                        <span>COPYRIGHT 2024 Mirabeau thaï spa</span>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
