// /var/www/website2024/dev.afaa.website/s-293-spatime-dev/src/api/api.js

// Define the base URL for the API
export const API_BASE_URL = 'https://py.testproject.work';


// Define the endpoints using the base URL
export const API_URLS = {
  BOOKING_AVAILABLE: `${API_BASE_URL}/booking/api/available`,
  PRICE_VIEW: `${API_BASE_URL}/price/api/view_app`,
  BOOKING_CREATE: `${API_BASE_URL}/booking/api/create_nulllogin`,
  BOOKING_DETAIL_CREATE: `${API_BASE_URL}/booking/api/detail_create`,
  SERVICES: `${API_BASE_URL}/services/api/view?status=1`,
};
